import cx from 'classnames';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import classes from './carousel.module.css';

const Carousel: FunctionComponent<{
  items: Array<{ value: ReactNode; key: string }>;
  perPage?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  doubleLine?: boolean;
  displayDots?: boolean;
}> = ({ items, perPage = 3, doubleLine = false, displayDots = false }) => {
  const [page, setPage] = useState(0);

  const doubleLinePages = perPage * 2;
  const pages = doubleLine
    ? Array(Math.ceil(items.length / doubleLinePages)).fill(0)
    : Array(Math.ceil(items.length / perPage)).fill(0);

  return (
    <div data-cy="carousel_slider">
      <div className="overflow-scroll lg:overflow-hidden">
        <ul
          className={cx(
            'grid grid-flow-col',
            `auto-cols-[calc(100%/1.25)]`,
            {
              'lg:auto-cols-[calc(100%/1)]': perPage === 1,
              'lg:auto-cols-[calc(100%/2)]': perPage === 2,
              'lg:auto-cols-[calc(100%/3)]': perPage === 3,
              'lg:auto-cols-[calc(100%/4)]': perPage === 4,
              'lg:auto-cols-[calc(100%/5)]': perPage === 5,
              'lg:auto-cols-[calc(100%/6)]': perPage === 6,
              'lg:auto-cols-[calc(100%/7)]': perPage === 7,
              'lg:auto-cols-[calc(100%/8)]': perPage === 8,
              'lg:auto-cols-[calc(100%/9)]': perPage === 9,
              'lg:auto-cols-[calc(100%/10)]': perPage === 10,
              'lg:auto-cols-[calc(100%/11)]': perPage === 11,
              'lg:auto-cols-[calc(100%/12)]': perPage === 12,
            },
            'flex-[1] shrink-0',
            'transition-transform duration-500 ease-in-out',
            'py-4',
            doubleLine
              ? 'grid-rows-2 auto-cols-[calc(100%/4)]'
              : 'grid-rows-[1fr]'
          )}
          style={{
            transform: doubleLine ? '' : `translate(-${page * 100}%, 0)`,
          }}
        >
          {items?.map(({ value, key }, index) => (
            <li
              className={cx(classes.item, 'px-4 items-center justify-center')}
              page={
                doubleLine
                  ? Math.ceil((index + 1) / doubleLinePages)
                  : Math.ceil((index + 1) / perPage)
              }
              key={key}
            >
              {value}
            </li>
          ))}
        </ul>
      </div>
      {pages.length > 1 && (
        <div
          className={cx(
            'justify-center align-middle pt-3 pb-6',
            displayDots ? 'flex max-lg:py-[20px]' : 'hidden lg:flex'
          )}
        >
          {pages?.map((_, index) => (
            <div
              role="presentation"
              key={index}
              className={cx(
                classes.dot,
                'h-6 w-6 p-1 cursor-pointer relative overflow-hidden',
                {
                  [classes.dotActive]: page === index,
                }
              )}
              onClick={() => {
                const carouselContainer = document.querySelector(
                  'div[data-cy="carousel_slider"] div'
                );
                if (carouselContainer) {
                  const positionInfo =
                    carouselContainer?.getBoundingClientRect();
                  if (index > page) {
                    carouselContainer.scrollLeft = positionInfo.width * index;
                  } else {
                    carouselContainer.scrollLeft = positionInfo.width * index;
                  }
                }
                setPage(index);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
