import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import {
  FunctionComponent,
  useState,
  memo,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import { useResolveLink } from '../../../hooks/use-link-props';
import classes from './top-brands.module.css';
import BPImage from '../../ui/v2/image';
import useIsMobile from '../../../hooks/use-is-mobile';
import Carousel from '../../ui/v2/carousel';

const TopBrands: FunctionComponent<{ brands: Array<TBrand> }> = ({
  brands,
}) => {
  const ref = useRef<HTMLElement>(null);
  const [shouldGetImages, setShouldGetImages] = useState(false);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const getLink = useResolveLink();
  const isMobile = useIsMobile();

  useEffect(() => {
    const watcher: IntersectionObserverCallback = (entries) => {
      if (entries[0].intersectionRatio !== 0) {
        setShouldGetImages(true);
      }
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    let observer: IntersectionObserver;

    if (typeof IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver(watcher, options);

      ref.current && observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const { brandPages, brandsToDisplay } = useMemo(() => {
    const brandsToDisplay = brands.filter((b) => b.is_top_brand);
    const pages = Math.ceil(brandsToDisplay.length / 8);

    return {
      brandPages: Array(pages)
        .fill(0)
        .map((v, i) => i),
      brandsToDisplay,
    };
  }, [brands]);

  return (
    <section className={cx('text-center')} ref={ref}>
      <div className={cx('')}>
        <div className="pt-9 lg:pb-0 container m-auto lg:pt-12 max-lg:px-4">
          <h2 className="text-2xl font-bold lg:px-6">
            {t('home:top-brands.title')}
          </h2>
          <p className="text-base lg:px-6 max-lg:pb-3 pb-[64px]">
            {t('home:top-brands.sub-title')}
          </p>
          <div className="lg:hidden">
            <Carousel
              doubleLine={true}
              displayDots={true}
              items={brandsToDisplay.map((item, index) => {
                const search = new URLSearchParams();
                search.set('brand', item.slug);

                return {
                  value: item.is_top_brand ? (
                    <div
                      className={cx(
                        classes.item,
                        'py-1 px-2 lg:p-2',
                        'cursor-pointer'
                      )}
                      key={item.id}
                    >
                      <Link
                        href={getLink('models', search.toString())}
                        passHref
                        legacyBehavior
                      >
                        <BPImage
                          src={`${process.env.STATIC_PREFIX}/brands/logos/${item.alias}.svg`}
                          alt={item.name}
                          width={isMobile ? 60 : 72}
                          height={isMobile ? 60 : 72}
                        ></BPImage>
                      </Link>
                    </div>
                  ) : null,
                  key: item.id.toString(),
                };
              })}
              perPage={isMobile ? 4 : 8}
            />
          </div>
          <div className={(classes.slider, 'max-lg:hidden overflow-hidden')}>
            <ul
              className={cx('lg:-mx-2', classes.list)}
              style={{
                transform: isMobile ? '' : `translate(-${page * 100}%, 0)`,
              }}
            >
              {brands.map((item, index) => {
                const search = new URLSearchParams();
                search.set('brand', item.slug);

                return item.is_top_brand ? (
                  <li
                    className={cx(
                      classes.item,
                      'py-1 px-2 lg:p-2',
                      'cursor-pointer'
                    )}
                    key={item.id}
                  >
                    <Link
                      href={getLink('models', search.toString())}
                      passHref
                      legacyBehavior
                    >
                      <BPImage
                        src={`${process.env.STATIC_PREFIX}/brands/logos/${item.alias}.svg`}
                        alt={item.name}
                        width={isMobile ? 60 : 72}
                        height={isMobile ? 60 : 72}
                      ></BPImage>
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
          <div className="max-lg:hidden flex justify-center align-middle pt-12">
            {brandPages?.map((value, index) => (
              <div
                role="presentation"
                key={value}
                className={cx(
                  classes.dot,
                  'cursor-pointer relative overflow-hidden',
                  {
                    [classes.dotActive]: page === index,
                  }
                )}
                onClick={() => {
                  setPage(index);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBrands;
